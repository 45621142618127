/*
==========================
COLORS
==========================
*/
$grey: #95989a;
$darker-grey: rgb(63, 63, 63);
$dark-grey: #5e5e5e;
$light-grey: #d1d1d1;
$pale-grey: #f7f7f7;
$black-grey: #2b353d;
$palest-grey: #f8f8f8;
$almost-white: #fdfdfd;
$light-blue: rgb(110, 157, 207);
$lightest-blue: rgb(195, 215, 236);
$theme-blue: $light-blue;
$darker-blue: rgb(23, 97, 175);
$hover-blue: rgb(103, 145, 190);
$attention-red: #f37070;
$light-red: #f5cbcb;

$pastel-pink: #f6e4e4;
$pastel-blue: #e4f4f6;
$pastel-purple: #e9e4f6;
$pastel-green: #eaf6e4;
$pastel-yellow: #f6f1e4;

/*
==========================
FONTS
==========================
*/
$font: 'Segoe UI', 'Open Sans', 'Helvetica Neue', Helvetica, 'Varela Round',
  sans-serif;

/*
==========================
STYLING
==========================
*/
$box-shadow: 0px 3px 4px $light-grey;
$box-shadow-light: 0px 3px 4px $pale-grey;
