@import '_variables.scss';

.resume-object {
  width: 100%;
  height: calc(100% - 45px);

  iframe {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 650px) {
  .resume-object {
    padding-top: 15px;
  }
}
