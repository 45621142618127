@import '_variables.scss';

/*
==========================
GRID
==========================
*/
.header-container-all {
  position: fixed;
  top: 0px;
  z-index: 10;
  left: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.938);
  border-bottom: 1px solid var(--grey-200);
  box-shadow: 0px 2px 9px var(--grey-200);
}
/*
==========================
MAIN HEADER
==========================
*/
.header-container {
  width: 100%;
  max-width: 1080px;
  width: 90vw;
  display: grid;
  grid-template-columns: 200px auto repeat(3, 100px);
  grid-template-areas: 'logo . about project resume';
  height: 30px;
  margin: 0 auto;
  justify-items: end;
  padding: 0px 20px;
}

.header-border {
  position: relative;
  top: 10px;
  border-top: 1px solid $pale-grey;
}

.nav-projects {
  grid-area: project;
}

.nav-about {
  grid-area: about;
}

.nav-resume {
  grid-area: resume;
}

.nav-blog {
  grid-area: blog;
}
/*
==========================
LINKS
==========================
*/
.logo,
.navigation {
  color: $dark-grey;
  font-size: 14px;
  margin-top: 15px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
}

.logo {
  justify-self: start;
  grid-area: logo;
  font-size: 18px;
  margin-top: 13px;
}

.logo:hover,
.navigation:hover {
  color: $darker-grey;
}

.nav-projects,
.nav-about {
  text-align: right;
}
/*
==========================
MOBILE HEADER
==========================
*/
.header-container--mobile {
  height: 30px;
  width: 90%;
  max-width: 1080px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'logo logo menu';
  height: 30px;
  margin: 0 auto;
  align-items: center;
  padding-top: 16px;

  .logo {
    margin: 0;
    justify-self: start;
    grid-area: logo;
  }
  .menu-icon {
    justify-self: end;
    grid-area: menu;
    margin-right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    i {
      font-size: 30px;
      color: $dark-grey;
    }
  }
  .menu {
    position: relative;
    z-index: 20;
    justify-self: end;
    grid-column: 2 / -1;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    width: 150px;
    vertical-align: middle;
    background-color: white;
    box-shadow: $box-shadow;
    padding: 15px;
    padding-bottom: 10px;
    align-items: center;
    justify-items: stretch;
  }
  .navigation {
    color: $dark-grey;
    text-align: left;
    padding: 10px;
    margin: 0px;
    &:hover {
      background-color: $lightest-blue;
    }
  }
  display: none;
}
/*
==========================
MEDIA QUERY
==========================
*/

@media all and (max-width: 1079px) and (min-width: 0px) {
  .header-container {
    padding: 0px 20px;
  }
}

@media all and (max-width: 789px) and (min-width: 0px) {
  .header-container--mobile {
    display: grid;
  }
  .header-container {
    display: none;
  }
}
