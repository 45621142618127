@import '_variables.scss';
@import 'breakpoints.scss';

@import '_variables.scss';

/*	
==========================	
USER INFO	
==========================	
*/
.user-info-section {
  grid-area: user;
  text-align: center;
}
.profile-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: $box-shadow;
}

.name {
  font-size: 26px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 700;
}

.location {
  font-size: 16px;
  font-weight: 500;
  margin-top: 0px;
}
/*	
==========================	
USER LINKS	
==========================	
*/
.user-links-section {
  margin-top: 10px;
}

.user-link-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin: 8px;
}

@media all and (max-width: 500px) and (min-width: 0px) {
  .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: $box-shadow;
  }
}
/*
==========================
BUTTON
==========================
*/
.btn-navi {
  text-align: center;
  color: white;
  background-color: var(--theme-color);
  top: 30px;
  padding: 10px 50px;
  border-radius: 20px;
  position: relative;
  text-decoration: none;
  box-shadow: 5px 5px 10px $light-grey;
}
.btn-navi:hover {
  background-color: $hover-blue;
}

/*
==========================
GRID & PROJECTS CONTAINER
==========================
*/
.projects-container {
  box-sizing: border-box;
}
.projects-border {
  border-left: 1px solid $light-grey;
}

.projects {
  margin-left: 180px;
  padding: 30px;
  padding-right: 0px;
  color: $dark-grey;
}

/*
==========================
SOCIAL CONTAINER
==========================
*/

.projects-container--header {
  background-color: var(--purple-100);
  padding: 40px 0;
  background-color: #fdf8e1;
  color: var(--grey-800);

  .content {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    text-align: left;
    width: var(--full-width);
    margin: 0 auto;
  }

  .header-info {
    justify-content: start;
    text-align: center;
    padding: 0 20px;
  }

  .projects-container-title {
    color: $dark-grey;
    font-size: 32px;
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
  }

  .projects-container-subtext {
    font-size: 20px;
    font-weight: 500;
    color: $dark-grey;
    margin: 0;
  }

  .social-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    i {
      font-size: 32px;
      color: var(--theme-color);
      margin: 16px;
      margin-left: 0;
    }
  }

  .profile-img {
    margin: 0 auto;
  }

  @media (min-width: $screen-md) {
    .content {
      grid-template-columns: min-content 1fr;
      grid-gap: 50px;

      .header-info {
        justify-content: start;
        text-align: left;
        padding: 0;
      }

      .social-container {
        justify-content: start;
      }

      .projects-container-title {
        font-size: 40px;
      }
    }
  }
}

.project-view-mode-controller {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.projects-view-mode-btn {
  background-color: white;
  border: none;
  cursor: pointer;
  i {
    font-size: 30px;
    color: $dark-grey;
    &:hover {
      color: var(--theme-color);
    }
  }
}

.projects-view-mode-btn--active {
  i {
    color: var(--theme-color);
  }
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  // padding: 30px;
  color: $dark-grey;

  .projects-border {
    display: none !important;
  }

  .project {
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: none;
    display: grid;
    grid-template-areas: 'i' 'd';
    grid-template-columns: repeat(1, 1fr);
    text-align: left;
    grid-gap: 20px;
    justify-items: left;
    align-items: start;
  }

  .project-timeline-dot {
    display: none;
  }

  .project-timeline-dot:hover {
    display: none;
  }

  .project-timeline-text {
    display: none;
  }

  .project-btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3px;
    display: none;
  }

  .project-description {
    display: none;
  }

  .btn-more {
    margin: 0px;
  }

  .project-coverPhoto {
    grid-area: i;
    width: 100%;
    box-shadow: $box-shadow;
  }
}
/*
==========================
PROJECT
==========================
*/
.project {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid $light-grey;
  display: grid;
  grid-template-areas: 'd d d i';
  grid-template-columns: repeat(4, 1fr);
  text-align: left;
  grid-gap: 20px;
}
/*
==========================
PROJECT INFO
==========================
*/
.project-info {
  grid-area: d;
}

.project-title {
  margin: 0px;
  font-weight: 600;
  font-size: 32px;
}

.project-tech {
  padding-bottom: 15px;
}

.project-description {
  margin-top: 1px;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 30px;
}

.project-tech-skill {
  border: 1px solid var(--grey-700);
  padding: 4px 10px;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  color: var(--grey-700);
  font-weight: 600;
  margin-top: 10px;
}

/*
==========================
IMAGE
==========================
*/
.project-coverPhoto {
  grid-area: i;
  width: 350px;
  height: 350px;
  object-fit: cover;
}
/*
==========================
BUTTON
==========================
*/
.project-btns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
.btn-more {
  background-color: var(--theme-color);
  color: white;
  border-radius: 25px;
  text-decoration: none;
  border: 1px solid $light-blue;
  padding: 6px 20px;
  box-shadow: $box-shadow;
  margin: 10px 0px;
  text-align: center;
}

.btn-more:hover {
  background-color: $hover-blue;
}

/*
==========================
TIMELINE
==========================
*/
.project-timeline-dot {
  position: relative;
  margin-left: -45px;
  margin-bottom: -35px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  border: 8px solid $light-grey;
  display: inline-block;
}

.project-timeline-text {
  position: relative;
  top: 25px;
  display: inline-block;
  text-align: right;
  width: 120px;
  margin-left: -160px;
  color: $grey;
}

/*
==========================
TALKS CONTAINER
==========================
*/

.section-title {
  text-align: center;
  font-size: 36px;
  color: var(--grey-800);
  margin-top: 50px;
  font-weight: 800;
  text-transform: uppercase;
}

.talk-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  align-items: start;
  padding: 0;

  h3,
  p {
    margin: 0;
  }

  p {
    line-height: 28px;
  }

  .talk-container--info-description {
    margin: 10px 0;
  }

  .talk-date {
    font-size: 16px;
    line-height: 34px;
  }

  iframe {
    width: 100%;
    height: auto;
    margin: 0;
  }
  a {
    color: var(--grey-700);
    font-weight: 600;
  }
}

.talks-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  margin-bottom: 100px;
  color: var(--grey-800);

  @media (min-width: $screen-lg) {
    .talk-container {
      grid-template-columns: 1fr 2fr;
    }
  }
}

/*
==========================
MEDIA QUERY
==========================
*/
@media all and (max-width: 1079px) {
  .project {
    grid-template-areas: 'i i i i' 'd d d d';
  }
  .projects {
    margin-left: 20%;
    width: 100%;
  }
  .project-coverPhoto {
    width: 100%;
    height: auto;
  }
  .talks-container {
    padding: 30px;
  }
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px;
    .project-title {
      font-size: 34px;
    }
    .project-btns {
      grid-template-columns: 1fr;
    }
  }
}

@media all and (max-width: 600px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
  .project {
    max-width: 100%;
    grid-template-areas: 'i i i i' 'd d d d';
  }
  .projects {
    margin-left: 0%;
  }
  .project-description {
    font-size: 12px;
  }
  .projects-border {
    border-left: none;
  }
  .project-timeline-dot {
    display: none;
  }
  .project-timeline-text {
    display: none;
  }
  .project-coverPhoto {
    width: 100%;
    height: auto;
  }
  .project-title {
    font-size: 30px;
  }
  .project-description {
    font-size: 17px;
  }
  .projects-container-title {
    font-size: 30px;
  }

  .projects-container-subtext {
    font-size: 16px;
  }
  .project-btns {
    grid-auto-flow: row;
  }
}
