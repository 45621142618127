// colors and fonts
@import '_variables.scss';

/*
==========================
BODY
==========================
*/

.App {
  font-family: $font;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  height: initial;
  margin: 0px;
  padding: 0px;
  white-space: pre-line;
  color: var(--grey-800);
}

/*
=========================
Full Bleed Layout Setup
=========================
*/
.wrapper {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr min(var(--screen-lg), 100%) 1fr;
}

.wrapper > * {
  grid-column: 2;
}

.full-bleed {
  width: 100%;
  grid-column: 1 / 4;
}

.below-header {
  margin-top: 40px;
}

:root {
  /*
  SCREEN SIZES
  */
  --screen-xs: 375px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1440px;

  --full-width: min(var(--screen-lg), 100%);

  /*
  MAX WIDTH
  */
  --max-width-body: var(--screen-xl);
  --max-width-max: var(--screen-2xl);

  /*
  COLORS
  */
  --theme-color: #fcc200;
  --theme-color-dark: #eeb600;
  --theme-color-medium: #fae588;
  --theme-color-light: #fdf8e1;
  --theme-color-secondary: #fdf8e1;

  --grey-900: #333333;
  --grey-800: #4f4f4f;
  --grey-700: #7f7f7f;
  --grey-600: rgb(158, 158, 158);
  --grey-500: #bbbbbb;
  --grey-400: #d9d9d9;
  --grey-300: #e6e6e6;
  --grey-200: #efefef;
  --grey-100: #f7f8fc;
  --blue-100: #eff2fa;
  --blue-200: rgb(170, 197, 219);
  --blue-300: #a2aec3;
  --blue-350: rgb(127, 149, 185);
  --blue-400: rgb(69, 140, 199);
  --blue-500: #466d98;
  --blue-600: rgb(54, 91, 131);
  --blue-700: rgb(64, 96, 131);
  --blue-900: rgb(77, 91, 105);
  --purple-1000: #19216c;
  --purple-900: #2d3a8c;
  --purple-800: #35469c;
  --purple-700: #4055a8;
  --purple-600: #4c63b6;
  --purple-500: #647acb;
  --purple-400: #7b93db;
  --purple-300: #98aeeb;
  --purple-200: #bed0f7;
  --purple-100: #e0e8f9;
  --red-1000: #610316;
  --red-900: #8a041a;
  --red-800: #ab091e;
  --red-700: #cf1124;
  --red-600: #e12d39;
  --red-500: #ef4e4e;
  --red-400: #f86a6a;
  --red-300: #ff9b9b;
  --red-200: #ffbdbd;
  --red-100: #ffe3e3;
  --green-1000: #014d40;
  --green-900: #0c6b58;
  --green-800: #147d64;
  --green-700: #199473;
  --green-600: #27ab83;
  --green-500: #3ebd93;
  --green-400: #65d6ad;
  --green-300: #8eedc7;
  --green-200: #c6f7e2;
  --green-100: #effcf6;

  --box-shadow: 0px 2px 6px var(--grey-300);
  --box-shadow-theme: 0px 2px 6px var(--theme-color-light);
}
