@import '_variables.scss';
/*
==========================
GRID
==========================
*/

.individual-project-container {
  color: $dark-grey;
}

/*
==========================
IMAGE
==========================
*/

.project-image {
  width: auto;
  height: 500px;
  object-fit: cover;
  margin: 0 auto;
  margin-top: 40px;
  box-shadow: 0px 15px 15px $light-grey;
  display: block;
}

.project-image--vertical {
  width: 100%;
  height: auto;
}

.project-image--horizontal {
  max-width: 100%;
  height: auto;
}

.project-image-padding {
  margin-top: 80px;
}

.project-image--medium {
  max-width: 50%;
  height: auto;
}

.project-image--small {
  max-width: 30%;
  height: auto;
}

.project-page-feature--video {
  margin: 30px auto;
  display: block;
  width: 100%;
  height: auto;
  min-height: 450px;
  max-height: 600px;
  max-width: 1000px;
}

/*
==========================
MAIN INFO
==========================
*/

.project-page-title {
  font-size: 40px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;
}

.project-page-description {
  width: 450px;
  text-align: left;
  font-size: 18px;
  margin: 0 auto;
}

.project-page-tech {
  width: 500px;
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-top: 20px;
}

/*
==========================
FEATURE
==========================
*/

.hline {
  width: 100%;
  border-top: 1px solid $light-grey;
  margin-top: -2px;
}

.project-page-feature {
  background: linear-gradient(white, $pale-grey);
  padding: 50px;
}

.project-page-feature-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.project-page-feature-description {
  width: 450px;
  margin: 0 auto;
  text-align: left;
  font-size: 18px;
  margin-top: 10px;
}

/*
==========================
WORK
==========================
*/

.project-page-work {
  display: block;
  width: 450px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: left;
}

.project-page-work--title {
  display: block;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  color: var(--grey-800);
  margin-top: 40px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.role-container {
  display: block;
  margin-bottom: 10px;
}

.role {
  max-width: 100%;
  display: grid;
  grid-template-columns: 25px auto;
  justify-items: start;
  grid-gap: 5px;
  margin-bottom: 15px;
}

.role-dot {
  justify-self: center;
  color: var(--theme-color);
  margin-top: 5px;
  margin-right: 10px;
}

.role-text {
  font-size: 18px;
  text-align: left;
  width: auto;
}

/*
==========================
BUTTON
==========================
*/

.project-page-btns {
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.main-navi-btn {
  display: inline-block;
  width: 150px;
  color: var(--grey-800);
  font-weight: 600;
  background-color: var(--theme-color);
  padding: 6px 15px;
  margin: 10px;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  box-shadow: 5px 5px 10px $light-grey;
}

.main-navi-btn:hover {
  background-color: var(--theme-color-dark);
}

/*
==========================
MEDIA QUERY
==========================
*/

@media all and (max-width: 1079px) {
  .project-image,
  .project-image--vertical,
  .project-image--horizontal {
    width: 100%;
    height: auto;
  }

  .project-page-description {
    width: 450px;
  }
}

@media (max-width: 650px) {
  .project-image,
  .project-image--vertical,
  .project-image--horizontal {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .project-page-feature--video {
    width: 100%;
    min-height: 250px;
  }
  .project-page-title {
    font-size: 35px;
    font-weight: 600;
  }
  .role {
    width: 100%;
  }
  .project-page-description,
  .project-page-work,
  .project-page-feature-description,
  .role-text {
    width: 80%;
    font-size: 16px;
  }

  .role-text {
    width: auto;
  }
  .project-page-tech {
    width: 80%;
  }
  .project-page-feature {
    padding: 10px;
    padding-bottom: 40px;
    padding-top: 30px;
  }
  .project-page-feature-title {
    margin: 0 auto;
    max-width: 80%;
    font-size: 24px;
  }
  .project-page-feature-description {
    margin: 0 auto;
    margin-bottom: -20px;
  }
}
