@import "_variables.scss";

.error-page-container {
  background-color: $pale-grey;
  width: 100vw;
  height: 100vh;
}
.error-image {
  width: 1080px;
  margin: 0 auto;
  object-fit: contain;
  display: block;
  top: 20vh;
  position: relative;
}
.error-goBack-btn {
  border: none;
  color: white;
  background-color: $attention-red;
  padding: 25px;
  font-size: 25px;
  font-weight: 700;
  box-shadow: 1px solid $light-grey;
  text-transform: uppercase;
  text-align: center;
  display: block;
  position: relative;
  top: 25vh;
  margin: 0 auto;
  cursor: pointer;
  width: 200px;
}
